import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React from "react";

interface Props {
  title?: string;
  value?: any;
  attrName?: any;
  value_update?: Function;
  data?: Array<any>;
  warn_status?: boolean;
  class_name?: string;
  error_message?: string;
  info_status?: boolean;
  info_message?: string;
  sx?: any;
  disabled?: boolean;
  custom?: string;
}

const defaultProps: Props = {
  title: "",
  value: "",
  attrName: "",
  warn_status: false,
  value_update: () => {},
  data: [],
  error_message: "select",
  info_status: false,
  info_message: "This is dummy text for select dropdown.",
  sx: "",
  disabled: false,
  custom: "",
};

const SelectDropdown: React.FC<Props> = ({
  title,
  value,
  value_update,
  attrName,
  data,
  warn_status,
  class_name,
  error_message,
  info_status,
  info_message,
  sx,
  disabled,
  custom,
}) => {
  return (
    <div className={class_name} style={{ ...sx }}>
      <FormControl fullWidth>
        <InputLabel>{title}</InputLabel>
        <Select
          id={title}
          onSelect={(e) => {
            e.currentTarget.blur();
          }}
          disabled={disabled}
          value={value}
          label={title}
          onChange={(e) => {
            value_update && value_update(attrName, e.target.value);
          }}>
          {data &&
            data.map((data) => (
              <MenuItem value={data.value}>{data.label}</MenuItem>
            ))}
        </Select>
      </FormControl>
      {info_status ? (
        <Tooltip title={info_message ?? ""}>
          <span className="infoMessage">
            <InfoOutlinedIcon />
          </span>
        </Tooltip>
      ) : null}
      {warn_status ? <span className="error">{error_message}</span> : null}
    </div>
  );
};

SelectDropdown.defaultProps = defaultProps;

export default SelectDropdown;
