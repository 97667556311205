import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  Button,
  Link,
  Menu,
  MenuItem,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  DialogTitle,
  Dialog,
} from "@mui/material";
// import { COLORS } from "../../../SupportingFiles/colors";
import "./Navbar.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CustomButton from "../../CustomButton/CustomButton";
import CloseIcon from "@mui/icons-material/Close";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useNavigate } from "react-router-dom";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";
import { COLORS } from "../../../SupportingFiles/colours";
import LoginContainer from "../../../Container/MyAccount/LoginContainer/LoginContainer";
import { MY_ACCOUNT_ROUTES } from "../../../Routing/Path/MyAccountRoutes";
import { useAppDispatch, useAppSelector } from "../../../Store/Store/hooks";
import { SESSION_CONSTANTS } from "../../../Services/Constants/SessionConstants";
import { FRONTEND_DOMAIN } from "../../../URLCollection/Domain";
import { HomeSlice } from "../../../Store/Reducer/Home/HomeSlice";
import { UserProfileSlice } from "../../../Store/Store/Slice_Reducer/Account/UserProfileSlice";

const emails = ["username@gmail.com", "user02@gmail.com"];

interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

function Navbar() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorE2, setAnchorE2] = useState<null | HTMLElement>(null);
  const [addBoxShadow, setAddBoxShadow] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [openCustomerLogin, setOpenCustomerLogin] = useState(false);
  const dispatch = useAppDispatch();
  const { USER_DATA } = useAppSelector((state) => state.USER_STATE);

  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorE2(null);
  };

  const handleLogout = () => {
    dispatch(UserProfileSlice.actions.setAccountUserDataMobile(""));
    sessionStorage.removeItem(SESSION_CONSTANTS.USER_MOBILE_NO);
    navigate("/");
  };

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
      if (position > 70) {
        setAddBoxShadow(true);
      } else {
        setAddBoxShadow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAccountClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE2(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDialogOpen = () => {
    setOpenCustomerLogin(true);
  };

  return (
    <Box
      sx={{
        background: COLORS.white,
        padding: "24px 12px",
        position: "fixed",
        top: "0px",
        left: "0px",
        boxShadow: addBoxShadow ? "0px 4px 12px rgba(0,0,0, 0.1)" : "none",
        zIndex: "91",
        width: "100%",
      }}
    >
      <Grid container spacing={3} alignItems="center">
        <Grid xs="auto">
          <Link
            onClick={() => {
              window.location.replace("/");
            }}
          >
            <img
              src="../images/BeScured_logo.svg"
              height="50px"
              alt="BeSecured"
            />
          </Link>
        </Grid>
        <Grid xs>
          <ul className="navLinks">
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.HOME)}>Home</Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.ABOUT_US)}>
                About us
              </Link>
            </li>
            <li>
              <Button
                id="basic-button"
                aria-controls={openMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? "true" : undefined}
                onClick={handleClick}
              >
                Products <KeyboardArrowDownIcon />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  onClick={() => navigate(COMMON_ROUTES.PRODUCT_HEALTH)}
                >
                  Health Insurance
                </MenuItem>
                <MenuItem onClick={() => navigate(COMMON_ROUTES.PRODUCT_CAR)}>
                  Car Insurance
                </MenuItem>
                <MenuItem onClick={() => navigate(COMMON_ROUTES.PRODUCT_TW)}>
                  Bike Insurance
                </MenuItem>
                <MenuItem onClick={() => navigate(COMMON_ROUTES.PRODUCT_TERM)}>
                  Term Insurance
                </MenuItem>
                {/* <MenuItem onClick={handleCloseMenu}>Investment Plan</MenuItem>
                <MenuItem onClick={handleCloseMenu}>Travel Insurance</MenuItem> */}
              </Menu>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.CONTACT_US)}>
                Contact us
              </Link>
            </li>
            {USER_DATA.mobile.value ? (
              <li className="ctaBtn">
                <Button
                  className="greenMdBtn rounded"
                  id="basic-button"
                  aria-controls={anchorE2 ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={Boolean(anchorE2)}
                  onClick={(e) => {
                    dispatch(HomeSlice.actions.setPageStatus("MY_ACCOUNT"));
                    handleAccountClick(e);
                  }}
                >
                  Hi! User
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorE2}
                  open={Boolean(anchorE2)}
                  onClose={handleClose}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    // onClick={() => {
                    //   handleClose();
                    //   window.location.href = `${FRONTEND_DOMAIN}${MY_ACCOUNT_ROUTES.DASHBOARD}${USER_DATA.mobile.value}`;
                    // }}
                    onClick={() => {
                      handleClose();
                      const encodedMobile = btoa(USER_DATA.mobile.value);
                      sessionStorage.setItem(
                        SESSION_CONSTANTS.USER_MOBILE_NO,
                        encodedMobile
                      );
                      window.location.href = `${FRONTEND_DOMAIN}${MY_ACCOUNT_ROUTES.DASHBOARD}${encodedMobile}`;
                    }}
                  >
                    My account
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </li>
            ) : (
              <li>
                <CustomButton
                  text_name="Login"
                  class_name="mediumPrimaryBtn"
                  onClickFunction={handleDialogOpen}
                />
              </li>
            )}
          </ul>
        </Grid>
      </Grid>
      <LoginContainer open={openCustomerLogin} setOpen={setOpenCustomerLogin} />
    </Box>
  );
}

export default Navbar;
