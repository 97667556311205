import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./MNavbar.scss";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../../SupportingFiles/colours";
import LoginContainer from "../../../Container/MyAccount/LoginContainer/LoginContainer";
import { MY_ACCOUNT_ROUTES } from "../../../Routing/Path/MyAccountRoutes";
import { useAppDispatch, useAppSelector } from "../../../Store/Store/hooks";
import { SESSION_CONSTANTS } from "../../../Services/Constants/SessionConstants";
import { FRONTEND_DOMAIN } from "../../../URLCollection/Domain";
import { HomeSlice } from "../../../Store/Reducer/Home/HomeSlice";
import { UserProfileSlice } from "../../../Store/Store/Slice_Reducer/Account/UserProfileSlice";

function MNavbar() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openCustomerLogin, setOpenCustomerLogin] = useState(false);
  const dispatch = useAppDispatch();
  const { USER_DATA } = useAppSelector((state) => state.USER_STATE);

  const handleLogout = () => {
    dispatch(UserProfileSlice.actions.setAccountUserDataMobile(""));
    sessionStorage.removeItem(SESSION_CONSTANTS.USER_MOBILE_NO);
    navigate("/");
  };
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpen(open);
    };

  const list = () => (
    <Box
      sx={{
        width: 220,
        zIndex: 9,
        paddingTop: "80px",
        paddingRight: "12px",
        paddingLeft: "12px",
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <Link onClick={() => navigate(COMMON_ROUTES.HOME)}>Home</Link>
        <Link onClick={() => navigate(COMMON_ROUTES.ABOUT_US)}>About Us</Link>
        <Link onClick={() => navigate(COMMON_ROUTES.CONTACT_US)}>
          Contact Us
        </Link>
        <Link>POS</Link>
        {USER_DATA.mobile.value ? (
          <>
            <Link
              onClick={(e) => {
                window.location.href = `${FRONTEND_DOMAIN}${
                  MY_ACCOUNT_ROUTES.DASHBOARD
                }${btoa(USER_DATA.mobile.value)}`;
              }}
            >
              Hi! User
            </Link>
            <Link onClick={handleLogout}>Logout</Link>
          </>
        ) : (
          <Link onClick={() => setOpenCustomerLogin(true)}>Login</Link>
        )}
      </List>
      <Divider />
      <p
        style={{
          fontSize: "14px",
          fontWeight: "600",
          display: "flex",
          justifyContent: "space-between",
          padding: "2px 12px",
        }}
      >
        <KeyboardArrowDownIcon /> Products
      </p>
      <Divider />
      <List>
        <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_HEALTH)}>
          Health Insurance
        </Link>
        <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_CAR)}>
          Car Insurance
        </Link>
        <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_TW)}>
          Bike Insurance
        </Link>
        <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_TERM)}>
          Term Insurance
        </Link>
        <Link>Investment Plans</Link>
        <Link>Travel Insurance</Link>
      </List>
    </Box>
  );

  return (
    <Box
      sx={{
        background: COLORS.white,
        padding: "24px 12px",
        position: "fixed",
        top: "0px",
        left: "0px",
        boxShadow: "0px 4px 12px rgba(0,0,0, 0.1)",
        zIndex: "999",
        width: "100%",
      }}
    >
      <LoginContainer open={openCustomerLogin} setOpen={setOpenCustomerLogin} />
      <Grid container spacing={3}>
        <Grid xs>
          <Link
            onClick={() => {
              window.location.replace("/");
            }}
          >
            <img
              src="../images/BeScured_logo.svg"
              height="32px"
              alt="BeSecured"
            />
          </Link>
        </Grid>
        <Grid xs="auto">
          <Button
            onClick={toggleDrawer(!open)}
            sx={{
              background: COLORS.themered,
              color: COLORS.white,
              minWidth: "20px",
              minHeight: "20px",
              padding: "2px 6px",
              ":hover": {
                background: COLORS.themeblue,
              },
            }}
          >
            <MenuIcon />
          </Button>
          <SwipeableDrawer
            anchor="right"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            {list()}
          </SwipeableDrawer>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MNavbar;
