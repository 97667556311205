import { Box, Button, Link, Dialog } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";

import RKTextField from "../../../Common/InputFields/RKTextField/RKTextField";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import CloseIcon from "@mui/icons-material/Close";
import EditNoteIcon from "@mui/icons-material/EditNote";
import CustomButton from "../../../CustomButton/CustomButton";

const CustomerLogin = ({
  open,
  setOpen,
  showEnterMobileSection,
  setShowEnterMobileSection,
  loginFields,
  setLoginFields,
  fieldsUpdateState,
  handleShowMessage,
  showMessage,
  resendOtp,
  closeModal,
}: {
  open: boolean;
  setOpen: any;
  showEnterMobileSection: boolean;
  setShowEnterMobileSection: any;
  loginFields: any;
  setLoginFields: any;
  fieldsUpdateState: Function;
  handleShowMessage: Function;
  showMessage: Function;
  resendOtp: Function;
  closeModal: () => void;
}) => {
  return (
    <Dialog open={open} className="modalPopup">
      {/* Step one */}
      {showEnterMobileSection ? (
        <Box padding="24px">
          <Grid container spacing={2} textAlign="center">
            <Grid xs={8} xsOffset={2}>
              <h4>Welcome</h4>
              <p>Please login and get your all policies details</p>
            </Grid>
            <Grid xs={2} textAlign="right">
              <Link className="close" onClick={closeModal}>
                <CloseIcon />
              </Link>
            </Grid>
            <Grid xs={12} className="my-4">
              <img src="../images/login_img1.svg" width="90%" />
            </Grid>
            <Grid xs={12} textAlign="center">
              <RKTextField
                class_name="inputField"
                title={"Enter Mobile Number"}
                value={loginFields.enterMobile.value}
                attrName={["enterMobile", "value", loginFields, setLoginFields]}
                value_update={fieldsUpdateState}
                warn_status={loginFields.enterMobile.warning}
                error_message={
                  isEmpty(loginFields.enterMobile.value)
                    ? "Enter Mobile Number"
                    : "Enter Valid Mobile Number"
                }
                max_length={10}
                validation_type="NUMBER"
              />
            </Grid>
            <Grid xs={12}>
              <CustomButton
                text_name="Send OTP"
                class_name="regularPrimaryBtn"
                onClickFunction={handleShowMessage}
              />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box padding="24px">
          <Grid container spacing={2} textAlign="center">
            <Grid xs={8} xsOffset={2}>
              <h4>Verify OTP</h4>
              <p>
                OTP has been sent successfully on <br />
                +91 -{" "}
                <span style={{ fontWeight: "600" }}>
                  {" "}
                  {loginFields.enterMobile.value}
                </span>{" "}
                <Link
                  sx={{
                    display: "inline-flex",
                    // color: COLORS.themered,
                    alignItems: "center",
                    position: "relative",
                    top: "6px",
                    left: "4px",
                  }}
                  onClick={() => setShowEnterMobileSection(true)}
                >
                  <EditNoteIcon /> Edit
                </Link>
              </p>
            </Grid>
            <Grid xs={2} textAlign="right">
              <Link className="close" onClick={closeModal}>
                <CloseIcon />
              </Link>
            </Grid>
            <Grid xs={12} className="my-4">
              <img src="../images/login_img1.svg" width="90%" />
            </Grid>
            <RKTextField
              class_name="inputField"
              title={"Enter OTP"}
              value={loginFields.enterOtp.value}
              attrName={["enterOtp", "value", loginFields, setLoginFields]}
              value_update={fieldsUpdateState}
              warn_status={loginFields.enterOtp.warning}
              error_message={
                isEmpty(loginFields.enterOtp.value)
                  ? "Enter OTP"
                  : "Enter Valid OTP"
              }
              max_length={4}
              validation_type="NUMBER"
            />
            <Grid xs={12}>
              <CustomButton
                text_name="Submit"
                class_name="regularPrimaryBtn"
                onClickFunction={showMessage}
              />
            </Grid>
            <Grid xs={12} textAlign="center" className="ctaBtn">
              <Button
                onClick={() => {
                  setLoginFields({
                    ...loginFields,
                    enterOtp: { value: "", warning: false },
                  });
                  resendOtp();
                }}
                className="greenXlBtn"
                style={{ width: "100%" }}
              >
                Resend OTP
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Dialog>
  );
};

export default CustomerLogin;
