import { NODE_DOMAIN, PHP_DOMAIN } from "../../CommonURLs/Domain";

const DOMAIN = process.env.REACT_APP_BACKEND_URL;

export const CAR_URLS = {
  GET_DETAILS_BY_QUOTE_NO: `${NODE_DOMAIN}/api/v1/premium/quotedetail`,
  ADD_FORM_DATA: `${PHP_DOMAIN}/api/car-insurances/add.json`,
  GET_PRODUCT_LIST: `${DOMAIN}/api/car-insurances/get-product-lists.json`,
  GET_CASHLESS_GARAGE_LIST: `${DOMAIN}/api/cashless-hospital-details/get-cashless-garage-details.json`,
  GET_VEHICLE_NO_INFO: `${PHP_DOMAIN}/api/car-insurances/get-vehicle-info.json`,
  GET_PREVIOUS_INSURER_LIST: `${PHP_DOMAIN}/api/previous-insurers/get-lists.json`,
  GET_VARIANT_LIST: `${PHP_DOMAIN}/api/car-master-datas/get-variant-lists.json`,
  GET_FUEL_TYPE_LIST: `${PHP_DOMAIN}/api/car-master-datas/get-fuels.json`,
  GET_MODEL_LIST: `${DOMAIN}/api/car-master-datas/get-models-by-make.json`,
  GET_MAKE_LIST: `${DOMAIN}/api/car-master-datas/get-lists.json`,
  GET_RTO_LIST: `${PHP_DOMAIN}/api/rto-product-maps/get-lists.json`,
  GET_MAKE_MODEL: `${PHP_DOMAIN}/api/car-master-datas/get-make-model.json`,
  UPDATE_DETAILS: `${DOMAIN}/api/car-insurances/update-policy-details.json`,
  UPDATE_ADDON_STATUS: `${DOMAIN}/api/car-insurances/update-addon.json`,
  UPDATE_IDV: `${NODE_DOMAIN}/api/v1/premium/cusIDV`,
  SEND_QUOTE_VIA_EMAIL: `${DOMAIN}/api/car-insurances/send-email-quote-page.json`,
  SEND_SINGLE_QUOTE_VIA_EMAIL: `${DOMAIN}/api/car-insurances/send-email-product-details.json`,
  PREMIUM_RECALCULATION: `${DOMAIN}/api/car-insurances/recalculate-premium.json`,
  GET_CITY_STATE_BY_PINCODE: `${DOMAIN}/api/state-city-maps/get-details.json`,
  CAPTURE_DATA: `${DOMAIN}/api/car-proposals/capture-record.json`,
  GET_CITY_STATE_BY_PINCODE_ONLY: `${DOMAIN}/api/state-city-maps/getDetailsByPincode.json`,
  GET_CITY_STATE_BY_RTO: `${DOMAIN}/api/rto-product-maps/get-details.json`,
  GET_SINGLE_QUOTE_COMPREHENSIVE: `${NODE_DOMAIN}/api/v1/premium/car`,
  GET_SINGLE_QUOTE_SAOD: `${NODE_DOMAIN}/api/v1/premium/car/saod`,
  GET_SINGLE_QUOTE_TP: `${NODE_DOMAIN}/api/v1/premium/car/tp`,
  UPDATE_CNG_KIT: `${NODE_DOMAIN}/api/v1/premium/update-cng-kit`,
  UPDATE_PASSENGER_COVER: `${NODE_DOMAIN}/api/v1/premium/update-passenger-cover`,
  BUY_ACTION: `${NODE_DOMAIN}/api/v1/premium/buy-action`,
  PAYMENT_URL: `${DOMAIN}/api/car-proposals/create-proposal.json`,
  BULK_UPDATE_ADDON_STATUS: `${NODE_DOMAIN}/api/v1/premium/update-bulk-addon`,
};
